export default function Separator(props) {
	
		
	return (
    <div
      style={{
        height: props.height,
      }}
    />
  );
}
